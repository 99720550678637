// ================================================
// COMPONENTS CE-ELEMENT CONTAO
// ce_text, ce_image
// ThemeImmobilien Waibel
// ================================================
// .ce_text.block{
//   overflow: hidden;
// }
#main {
  div[class^="ce_"] {
	margin-bottom: $baseline;
	&.block {
	  overflow: hidden;
	}
	// &:last-child {
	//   margin-bottom: 2 * $baseline;
	// }
  }
  .ce_text {
	p {
	  &:last-child {
		margin-bottom: 0;
	  }
	}
  }
  figure[class*="float_"] {
	@include small {
	  display:   flex;
	  flex-flow: column;
	  //width:     75%;
	}
	@include medium {
	  display: block;
	  // width: calc(50% - 1rem);
	}
	@include xlarge {
	  // width: calc(50% - 1.5rem);
	}
  }
  .image_container {
	margin-bottom: 1rem;
	img {
	  padding: 5px;
	  border:  $border;
	}
	&.float_left {
	  @include medium {
		float:        left;
		margin-right: 2rem;
	  }
	}
	&.float_right {
	  @include medium {
		float:       right;
		margin-left: 2rem;
	  }
	}
  }
}

@keyframes figcaption_overlay {
  0% {
	width:     0;
	height:    0;
	font-size: 0;
  }
  100% {
	width:     100%;
	height:    auto;
	font-size: $font-size-large;
  }
}

.image_container {
  &--overlay {
	position: relative;
	figcaption {
	  font-family:               "Lobster", sans-serif;
	  font-size:                 $font-size-large;
	  text-align:                left;
	  position:                  absolute;
	  top:                       0;
	  left:                      0;
	  width:                     100%;
	  padding:                   0.25rem 0 0 1rem;
	  // background-color: rgba(#fff, 0.5);
	  animation:                 figcaption_overlay;
	  animation-duration:        3s;
	  animation-timing-function: ease;
	}
  }
}

.ce_text--invers {
  margin-top: $baseline;
  @include medium {
	display:         flex;
	justify-content: flex-end;
	// margin-left: 40%;
  }
  p {
	background: rgba($bg-color-invers, 0.9) url(/files/theme_waibel/img/email.png) 97% center no-repeat;
	color:      $color-invers;
	margin:     0;
	padding:    1rem 4rem 1rem 1rem;
	border:     2px solid $border-color-accent;
  }
  a {
	font-weight: 700;
	&:hover {
	  color: $color-invers-alternative;
	}
  }
}
