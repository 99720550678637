// ================================================
// LAYOUT Footer
// ThemeImmobilien Waibel
// ================================================

#footer {
  // grid-area: footer;
  // margin-top: 3rem;
  border-top: 2px solid $border-color-alternative;
  @include large {
    // width: 85%;
    // max-width: 1100px;
    border-right: 2px solid $border-color-alternative;
    border-top-right-radius: 50px;
  }
  .inside {
    padding: 1rem 1rem 0;
    @include large {
      padding: 1rem 1.2rem 0;
    }
  }
  .slogan {

  }
}
