// ================================================
// COMPONENTS Mod_catalog Immobilien
// Theme Immobilien Waibel
// ================================================

.ctlg_teaser {
  display: grid;
  grid-template-columns: 100%;
  // grid-template-rows: repeat(7, auto [row-start]);
  padding: $baseline 0;
  grid-template-areas:
    "bild"
    "titel"
    "teasertext"
    // "objektart"
    "eigenschaften"
    "more";
  @include small {
    grid-template-columns: 9rem 1fr;
    // grid-template-rows: repeat(7, auto [row-start]);
    grid-column-gap: 1rem;
    grid-template-areas:
      "bild titel"
      "bild teasertext"
      // "bild objektart"
      "bild eigenschaften"
      "bild more";
    border-bottom: none;
  }
  p {
    margin: 0;
  }
  .teaserbild {
    grid-area: bild;
    // align-self: center;
    img {
      border: 8px solid $border-color-accent;
      border-radius: 100%;
      @include small {
        width: calc(100% - 2 * 8px);
      }
    }
  }
  .teasertext {
    grid-area: teasertext;
    font-size: $font-size-normal;
    @include min(45em) {
      font-size: $font-size-small;
    }
    @include xlarge {
      font-size: $font-size-normal;
    }
  }
  .title {
    grid-area: titel;
    font-size: $font-size-normal;
    font-weight: 700;
    border-bottom: 1px solid $border-color;
    margin: 0;
  }
  .objektart {
    grid-area: objektart;
    // background-color: $bg-color-accent;
    // color: $color;
    font-weight: bold;
    font-style: italic;
    // text-transform: uppercase;
    // font-variant: small-caps;
  }
  .ctlg_entities {
    grid-area: eigenschaften;
    font-size: $font-size-normal;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include min(45em) {
      font-size: $font-size-small;
      display: flex;
      flex-flow: wrap;
    }
    @include xlarge {
      font-size: $font-size-normal;
    }
    .ctlg_entity {
      margin-right: 1rem;
    }
    .ctlg_entity_name {
      font-weight: 700;
    }
    .ctlg_entity_trenner {
      @include min(45em) {
        display: none;
      }
    }
    span {
      @include min(45em) {
        display: block;
      }
    }
    .zimmer,
    .preis,
    .wfl,
    .gfl {
      .ctlg_entity_value {
        @include min(45em) {
          text-align: right;
        }
      }
    }
  }
  .more {
    grid-area: more;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 700;
    &:after {
      content: '';
      background: url(../img/pfeil_verlauf_2.svg) no-repeat;
      width: 5rem;
      height: 2.5rem;
    }
    p {
      margin: 0 -1.5rem 0 0;
      .ie11 & {
        padding-top: 0.19rem;
      }
    }
  }
}
.view--short {
  width: 50%;
  background-color: rgba($bg-color-invers, 0.7);
  color: $color-invers;
  position: fixed;
  top: 200px;
  left: 200px;
  z-index: 90;
  transition: 1s ease;
  border-top: 2px solid $border-color-accent;
  border-bottom: 2px solid $border-color-accent;
  display: none;
}
.ctlg_teaser--short {
  padding: 5px;
  &:nth-child(2n) {
    background-color: rgba($bg-color-accent, 0.7);
  }
  .ctlg_entities {
    font-size: $font-size-normal;
    list-style-type: none;
    padding: 0;
    margin: 0;
    @include min(45em) {
      font-size: $font-size-small;
      // display: flex;
      // flex-flow: wrap;
    }
    @include xlarge {
      font-size: $font-size-normal;
    }
    .ctlg_entity {
      // margin-right: 1rem;
    }
    .ctlg_entity_name {
      font-weight: 700;
    }
    .ctlg_entity_trenner {
      @include min(45em) {
        // display: none;
      }
    }
    span {
      @include min(45em) {
        // display: block;
      }
    }
    .preis {
      .ctlg_entity_value {
        @include min(45em) {
          // text-align: right;
        }
      }
    }
  }
}
.ctlg_master {
  .ctlg_h2 {
    color: lighten($color, 75%);
    font-size: 0.8 * $font-size-h2;
    font-weight: 700;
    text-transform: uppercase;
  }
  p {
    &.teasertext {
      margin-bottom: 0;
    }
    &.standort {
      color: $color-accent;
      font-style: italic;
    }
  }
  img[src*="platzhalter"]{
    padding: 0 !important;
    border: 8px solid $border-color-accent !important;
    border-radius: 100%;
    width: 239px;
    height: 239px;
  }
  .ctlg_entity_name,
  .ctlg_entity_value.nutzung {
    font-weight: 700;
  }
  .entity_boxes {
    @include medium {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: $baseline;
    }
    .ie11 & {
      grid-template-areas:
      "details ausstattung"
      "energieausweis sonstiges"
      "provision"
    }
    @include xlarge {
      grid-column-gap: 3rem;
    }
  }
  .entity_box {
    ul {
      list-style-type: none;
      margin: 0.5 * $baseline 0;
      padding: 0;
    }
    &:nth-child(1)  {
      .ie11 & {
        grid-area: details;
      }
    }
    &:nth-child(2)  {
      .ie11 & {
        grid-area: ausstattung;
      }
    }
    &:nth-child(3)  {
      .ie11 & {
        grid-area: energieausweis;
      }
    }
    &:nth-child(4)  {
      .ie11 & {
        grid-area: sonstiges;
      }
    }
    &:nth-child(5)  {
      .ie11 & {
        grid-area: provision;
      }
    }
  }
  .back {
    font-weight: 700;
    display: flex;
    align-items: center;
    &:before {
      content: '';
      background: url(../img/pfeil_verlauf_2.svg) no-repeat;
      width: 5rem;
      height: 2.5rem;
      transform: scaleX(-1);
    }
    p {
      margin: 0 0 0 -1.5rem;
      .ie11 & {
        padding-top: 0.19rem;
      }
    }
  }
}
