// ================================================
// COMPONENTS CE-GALLERY CONTAO
// ThemeImmobilien Waibel
// ================================================
#main {
  .ce_gallery {
	ul {
	  display:               grid;
	  align-items:           center;
	  margin:                0;
	  padding:               0;
	  list-style-type:       none;
	  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	  grid-gap:              1rem;
	  justify-items:         center;
	  //@include xsmall {
	  //grid-template-columns: 1fr 1fr;
	  //}
	  //@include small {
	  //grid-template-columns: 1fr 1fr 1fr;
	  //}
	  //@include min(45em) {
	  //grid-template-columns: 1fr 1fr 1fr 1fr;
	  //}
	}
	.image_container {
	  //text-align:    center;
	  //margin-bottom: 0;
	  //@include min(45em) {
	  //text-align: left;
	  //}
	  margin: 0;
	  img {
		object-fit: contain;
	  }
	}
  }
}
