/*
-------------------------------------------------
Google Fonts lokal einbinden
-------------------------------------------------
*/
// Alegreya Sans
@include font-face(
				"Alegreya Sans",
				"../font/alegreya_sans/alegreya-sans-v8-latin-regular",
						("woff2", "woff")
) {
  font-weight: normal;
  font-style:  normal;
}

@include font-face(
				"Alegreya Sans",
				"../font/alegreya_sans/alegreya-sans-v8-latin-700",
						("woff2", "woff")
) {
  font-weight: 700;
  font-style:  normal;
}

@include font-face(
				"Alegreya Sans",
				"../font/alegreya_sans/alegreya-sans-v8-latin-italic",
						("woff2", "woff")
) {
  font-weight: normal;
  font-style:  italic;
}

@include font-face(
				"Alegreya Sans",
				"../font/alegreya_sans/alegreya-sans-v8-latin-700italic",
						("woff2", "woff")
) {
  font-weight: 700;
  font-style:  italic;
}

// Lobster
@include font-face(
				"Lobster",
				"../font/lobster/lobster-v20-latin-regular",
						("woff2", "woff")
) {
  font-weight: normal;
  font-style:  normal;
}

// Oleo Script Swash Caps-700 - latin
@font-face {
	font-family: 'Oleo Script Swash Caps';
	font-style: normal;
	font-weight: 700;
	src: url('../font/oleo/oleo-script-swash-caps-v11-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
	url('../font/oleo/oleo-script-swash-caps-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../font/oleo/oleo-script-swash-caps-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
	url('../font/oleo/oleo-script-swash-caps-v11-latin-700.woff') format('woff'), /* Modern Browsers */
	url('../font/oleo/oleo-script-swash-caps-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
	url('../font/oleo/oleo-script-swash-caps-v11-latin-700.svg#OleoScriptSwashCaps') format('svg'); /* Legacy iOS */
}


