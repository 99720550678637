// ================================================
// COMPONENTS Headergrafik
// ThemeImmobilien Waibel
// ================================================

.headergrafik {
  display: none;
  // width: 218px;
  @include large {
    display: block;
  }
  img {
    border: 1px solid $border-color-alternative;
    outline: 1px solid $border-color-accent-alternative;
  }
}
