// ================================================
// COMPONENTS Kontakt
// ThemeImmobilien Waibel
// ================================================


%kontakt {
  p {
    font-size: $font-size-small;
    line-height: 0.75 * $line-height;
  }
}
.kontakt {
  @extend %kontakt;
  p {
    margin: 0;
  }
}

// Fixer Button fuer schnelles/permanentes anzeigen von Kontaktinformationen
.kontakt--fixed {
  @extend %kontakt;
  color: $color-invers;
  background: rgba($bg-color-invers, 0.9) url(../img/phone.png) 0.8% center no-repeat;
  background-size: 27px;
  position: fixed;
  z-index: 90;
  transition: 1s ease;
  border-top: 2px solid $border-color-accent;
  border-bottom: 2px solid $border-color-accent;
  padding: 1.4rem .2rem 1.4rem 2.2rem;
  @include max($b-medium-max) {
    border-left: 2px solid $border-color-accent;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
  // @include only-xxsmall {
  //   // right: -16.6rem;
  //   right: -18rem;
  // }
  @include only(25em,30.999em) {
    right: -17.7rem;
  }
  @include only(31em,37.999em) {
    right: -18rem;
  }
  @include only-small {
    right: -17.6rem;
  }
  @include only-medium {
    right: -20.5rem;
    padding: 1.75rem .5rem 1.75rem 4.75rem;
  }
  @include max-xxsmall {
    right: -17.9rem;
  }
  @include medium {
    background-size: auto;
  }
  @include large {
    background: rgba($bg-color-invers, 0.9) url(../img/phone.png) 98% center no-repeat;
    left: -22rem;
    border-right: 2px solid $border-color-accent;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 1.75rem 5.4rem 1.75rem 1rem;
  }
  &:hover {
    @include max($b-medium-max) {
      right: 0;
    }
    @include large {
      left: 0;
    }
  }
  p {
    margin: 0;
    @include medium {
      font-size: $font-size-normal;
    }
  }
}
