// ================================================
// COMPONENTS Navigation
// ThemeImmobilien Waibel
// ================================================

nav {
  ul {
    list-style-type: none;
  }
  ul {
    margin: 0;
    padding: 0;
  }
}
#navigation {
  border-bottom: 5px solid $border-color-alternative;
  box-shadow:-2px 2px 5px black;
  .inside {
    border-bottom: 1px solid $border-color-invers;
  }
}

.nav_main {
  padding: 2rem 1.5rem;
  background-color: $bg-color-accent;

  .level_1 {
    margin-left: 2rem;
    @include large {
      margin: 0;
    }
    > li {
      text-transform: uppercase;
    }
  }
  .level_2 {
    margin-left: 2rem;
    > li {
      text-transform: none;
      padding: 0 0 0.2rem 0.5rem;
      border-bottom: 1px dotted $border-color-invers;
      &:last-child {
        border: none;
      }
    }
  }
  .active,
  .trail.sibling {
    color: $color-invers;
    font-weight: 400;
  }
  a {
    color: $color;
  }
}

.nav_meta {
  text-align: center;
  @include medium {
    font-size: $font-size-small;
  }
  a {
    color: $color;
    &:hover {
      text-decoration: underline;
    }
  }
  strong {
    font-weight: 400;
    text-decoration: underline;
  }
  .level_1 {
    a,
    strong {
      display: block;
      padding: 0.5rem;
    }
    @include medium {
      display: flex;
      > li {
        &:after {
          content: " | ";
        }
        &.last {
          &:after {
            content:"";
          }
        }
        a,
        strong {
          display: inline;
        }
      }
    }
  }
}
