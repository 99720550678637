// ================================================
// COMPONENTS Logo
// ThemeImmobilien Waibel
// ================================================

.logo {
  width: 45%;
  max-width: 256px;
  @include medium {
    width: 220px;
  }
  @include large {
    width: 256px;
  }
}
