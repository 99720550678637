// ================================================
// COMPONENTS Slogan
// ThemeImmobilien Waibel
// ================================================

.slogan {
  font-family: "Lobster", sans-serif;
  font-size: $font-size-large;
  text-align: center;
  padding: 1rem 0;
  p {
    margin: 0;
  }
}
