/*
================================================================================
Settings - Immobilien Waibel
Autor: Maren Lange
================================================================================
*/
// Layout
$min-width-page:                  320px;
$max-width-page:                  55rem;
$min-height-content:              300px;
$width-page:                      100%;
$width-content:                   95%;
$distance:                        1rem;
// Breakpoints
$b-xxsmall:                       20em;
$b-xxsmall-max:                   29.9em;
$b-xsmall:                        30em;
$b-xsmall-max:                    37.999em;
$b-small:                         38em;
$b-small-max:                     41.999em;
$b-medium:                        42em;
$b-medium-max:                    63.999em;
$b-large:                         64em;
$b-large-max:                     89.999em;
$b-xlarge:                        90em;
$b-xlarge-max:                    119.999em;
$b-xxlarge:                       120em;
// Typography global
// $sans-serif           : $verdana;
$sans-serif:                      "Alegreya Sans", sans-serif;
$font-family:                     $sans-serif;
$font-family-headline:            $font-family;
// Fluid Typography
$screen-min:                      25em;
$screen-max:                      90em;
$basefont-min:                    1rem; // 16px
// $basefont-min    : 1.125rem; // 18px
$basefont-max:                    1.125rem; // 18px
// $basefont-max    : 1.25rem; // 20px
$baseline:                        1.625rem;
$line-height:                     $baseline / 1rem;
$font-size-xsmall:                0.625rem;
$font-size-small:                 0.88rem;
$font-size-normal:                1rem;
$font-size-large:                 1.1rem;
$font-size-xlarge:                1.5rem;
$font-weight:                     400;
// Headlines
$font-size-h1:                    1.5rem;
$font-size-h2:                    1.3125rem;
$font-size-h3:                    1.25rem;
$font-size-h4:                    1rem;
$font-size-h5:                    1rem;
$font-size-h6:                    1rem;
$font-weight-headline:            400;
$font-style-header-special:       italic;
$font-size-header-special:        0.875em;
// Color-Schema
// How to use color variables:
// https://www.mediaevent.de/tutorial/farbcodes.html
// https://www.w3schools.com/colors/colors_names.asp
// http://www.colors.commutercreative.com
// http://davidwalsh.name/sass-color-variables-dont-suck
// http://chir.ag/projects/name-that-color/
// http://www.color-blindness.com/color-name-hue/
// TODO Welche Farben werden genutzt? Einsatz Farbschatierungen verbessern/veraendern
// Colorset
$yellow-green:                    #cfe77b;
$conifer:                         #badc49;
$atlantis:                        #9fbf28;
$jewel:                           #176d3a;
$kabul:                           #5b4d46;
$tuna:                            #323235;
// Background Colors global
$bg-color:                        white;
$bg-color-alternative:            $kabul;
$bg-color-invers:                 $tuna;
$bg-color-accent:                 $conifer;
// $bg-color-accent-alternative: $cream-brullee;
$bg-color-error:                  $jewel;
// Colors global
$color:                           black;
$color-invers:                    #f5f5f5; // whitesmoke
$color-alternative:               $kabul;
$color-accent:                    $atlantis;
$color-accent-alternative:        $jewel;
$color-invers-alternative:        $yellow-green;
$color-error:                     whitesmoke;
//Link Colors und Button Colors
$color-link:                      $color-accent;
$color-link-link:                 $color-link;
$color-link-visited:              $color-link;
$color-link-hover:                $kabul;
$color-link-focus:                $color-link-hover;
$color-link-active:               $color-link-hover;
// $color-button-hover : $color-link-hover !default;
// Borders and Shadows
$border-color:                    black;
$border-color-alternative:        $kabul;
$border-color-invers:             white;
$border-color-accent:             $conifer;
$border-color-accent-alternative: $atlantis;
$border:                          1px solid lighten($border-color, 90%);
$border-radius:                   5px;
// Metanavigation
// $color-nav-meta         : $concrete;
// $color-nav-meta-hover   : #fff;
// $color-nav-meta-current : $color-nav-meta-hover;
// Form
// $bg-form-field      : lighten($suva-gray, 35%);
// $bg-form-field-focus: lighten($suva-gray, 20%);
// $border-form-field  : 1px solid $suva-gray;
// $bg-submit: url('../img/bg_submit.png') no-repeat left center transparent;
