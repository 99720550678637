// ================================================
// LAYOUT Layout
// Theme Immobilien Waibel
// ================================================

#wrapper {
  min-width: $min-width-page;
  width: 100%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    "header"
    "sidebar"
    "main"
    "footer";
  grid-row-gap: 1.5rem;
  @include large {
    grid-column-gap: 2rem;
    grid-row-gap: 5rem;
    grid-template-columns: 0.1rem 13rem 1fr 1fr 1fr 1fr 0.1rem;
    grid-template-rows: auto;
    grid-template-areas:
      ". . header header header header header"
      ". sidebar main main main main ."
      "footer footer footer footer footer . ."
  }
  @include min(72em) {
    grid-column-gap: 2rem;
    grid-template-columns: 0.5rem 16rem 1fr 1fr 1fr 1fr 0.5rem;
    grid-template-rows: auto;
    grid-template-areas:
    ". . header header header header header"
    ". sidebar main main main main ."
    "footer footer footer footer footer . ."
  }
  @include xlarge {
    grid-column-gap: 3rem;
    grid-template-columns: 6rem 16rem 1fr 1fr 1fr 1fr 2rem;
    grid-template-rows: auto;
    grid-template-areas:
    ". . header header header header header"
    ". sidebar main main main main ."
    "footer footer footer footer . . ."
  }
}
#header {
  grid-area: header;
  .ie11 & {
    @include large {
      margin-bottom: 5rem;
    }
  }
}
.custom {
  grid-area: sidebar;
}
#container {
  grid-area: main;
  .ie11 & {
    @include large {
      margin-bottom: 5rem;
    }
  }
}
#footer {
  grid-area: footer;
}
