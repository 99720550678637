// ================================================
// COMPONENTS CE-FORM CONTAO
//
// ThemeImmobilien Waibel
// ================================================
fieldset {
  padding: 0;
  border: none;
  margin: 0;
  legend {
    font-size: $font-size-h3;
  }
}
input {
  &.readonly {
    font-weight: 700;
    font-size: $font-size-large;
    // margin-left: 1.2rem;
    border: none;
  }
}
.ce_form {
  &.frm_kontakt {
    max-width: 40rem;
  }
}
.widget {
  display: flex;
  flex-flow: wrap;
  margin: 0.25 * $baseline 0;
  input,
  select,
  textarea {
    font-family: $font-family;
    flex: 1 16rem;
    padding: 0.25rem;
  }
  label {
    flex: 1 18rem;
    @include small {
      flex: 0 14rem;
    }
  }
  p {
    &.error {
      background-color: $bg-color-error;
      color: $color-error;
      font-weight: bold;
      flex: 1 18rem;
      order: -1;
      padding: 0.5rem;
      border: 2px solid $bg-color-accent;
      margin: 0.5rem 0;
      @include small {
        flex: 0 100%;
      }
    }
  }
}
.widget-checkbox {
  input {
    margin-right: 0.25rem;
  }
  legend {
    display: none;
  }
  span {
    display: block;
    @include small {
      display: inline;
      margin-right: 1rem;
    }
  }
}
.widget-radio {
  legend {
    display: none;
  }
  span {
    margin-right: 1rem;
  }
}
.widget-textarea,
.widget-text.fullwidth {
  label {
    flex: 0 100%;
  }
}
.widget-explanation {
  p {
    margin: 0;
  }
}
.conditional_form_field {
  background-color: rgba($bg-color-alternative, 0.1);
  padding: 1rem;
  border: 1px solid $border-color-accent-alternative;
  // border-radius: 5px;
  margin: 1rem 0;
}
label.dependent.mandatory,
.checkbox_container.dependent.mandatory label,
.radio_container.dependent.mandatory {
  &:after {
    content: '*';
  }
}
.widget-submit {

}
button {
  &.submit {
    background:  rgba($bg-color-invers, 0.9) url(/files/theme_waibel/img/email.png) 97% center no-repeat;
    color: $color-invers;
    font-size: $font-size-large;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    border: 2px solid $border-color-accent;
    // border-radius: 10px;
    padding: 1.2rem 4rem 1.2rem 1rem;
    margin-left: auto;
    cursor: pointer;
  }
}
