// ================================================
// LAYOUT main
// Theme Immobilien Waibel
// ================================================

#main {
  .inside {
    padding: 0 1rem;
    @include large {
      padding: 0;
      max-width: $max-width-page;
    }
  }
}
