// ================================================
// LAYOUT Header
// ThemeImmobilien Waibel
// ================================================

#header {
  // grid-area: header;
  margin-bottom: 1.5rem;
  @include medium {
    margin-bottom: 3rem;
  }
  @include large {
    border-bottom: 5px solid $border-color-alternative;
    box-shadow:-2px 2px 5px black;
    margin: 4.5rem 0 0 0;
  }
  @include xlarge {
    margin: 4.5rem 0 0 0;  
  }
  .inside {
    padding: 1rem;
    @include medium {
      display: flex;
    }
    @include large {
      border-bottom: 25px solid $border-color-accent;
      padding: 0 1.2rem 1rem;
      margin-bottom: 1px;
    }
    @include xlarge {
      padding: 0 3% 1rem;
    }
  }
  .logo {
    .image_container {
      margin: 0;
      @include large {
        margin-top: -64px;
      }
    }
  }
  .kontakt {
    margin-top: 2rem;
    @include medium {
      margin-top: 4rem;
      margin-left: 3rem;
    }
    @include large {
      margin-top: 1rem;
      margin-left: 4.5%;
    }
  }
  .headergrafik {
    margin-left: auto;
    .image_container {
      @include large {
        margin-top: 0.4rem;
        margin-bottom: -30px;
      }
    }
  }
  .kontakt--fixed {
    top: 2rem;
    @include large {
      top: 8.9rem;
    }
  }
}
