/*
-------------------------------------------------
GENERAL/BASE
Basis Einstellungen
-------------------------------------------------
*/
// default verwendete HTML-Elemente
$use-hr                      : false !default;
$use-blockquote              : false !default;
$use-cite                    : false !default;
// default Typography
$sans-serif                  : $font-stack-verdana !default;
$serif                       : $font-stack-georgia !default;
$font-family                 : $sans-serif !default;
$font-family-headline        : $font-family !default;
// default Fluid Typography global
$screen-min                  : 20rem !default;
$screen-max                  : 100rem !default;
$basefont-min                : 1rem !default;
$basefont-max                : 1.125rem !default;
$baseline                    : 1.625rem !default;
$font-size-xsmall            : 0.625rem !default;
$font-size-small             : 0.875rem !default;
$font-size-normal            : 1rem !default;
$font-size-large             : 1.25rem !default;
$font-size-xlarge            : 1.5rem !default;
$font-weight                 : 400 !default;
// default Typography Headlines
$font-size-h1                : 1.5rem !default;
$font-size-h2                : 1.3125rem !default;
$font-size-h3                : 1.25rem !default;
$font-size-h4                : 1.125rem !default;
$font-size-h5                : 1.125rem !default;
$font-size-h6                : 1.125rem !default;
$font-weight-headline        : 700 !default;
$font-style-header-special   : italic !default;
$font-size-header-special    : 0.875em !default;
// default Font Colors
$color                       : black !default;
$color-invers                : white !default;
$color-alternative           : gray !default;
$color-accent                : mediumblue !default;
$color-accent-alternative    : deepskyblue !default;
// default Background
$bg-color                    : white !default;
$bg-color-invers             : black !default;
$bg-color-alternative        : gray !default;
$bg-color-accent             : mediumblue !default;
$bg-color-accent-alternative : deepskyblue !default;
//default Link Colors und Button Colors
$color-link                  : mediumblue !default;
$color-link-link             : mediumblue !default;
$color-link-visited          : gray !default;
$color-link-hover            : darkred !default;
$color-link-focus            : darkred !default;
$color-link-active           : darkred !default;
$color-button                : mediumblue !default;
$color-button-hover          : darkred !default;
// default Borders and Shadows
$border                      : 1px solid gray !default;
$shadow                      : 0 0 5px black !default;
$shadow-drop-shadow          : 4px 4px 5px gray !default;
// *****************************************************************************
// HTML
html {
  @include fluid-typo(font-size, $screen-min, $screen-max, $basefont-min, $basefont-max);
  font-weight    : $font-weight;
  height         : 100%;
  font-smoothing : antialiased;
  text-rendering : optimizeLegibility;
}

// Border-Box Empfehlung Paul Irish
// css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
// paulirish.com/2012/box-sizing-border-box-ftw
html {
  box-sizing : border-box;
}

* {
  &,
  &:before,
  &:after {
    box-sizing : inherit;
  }
}

// BODY
body {
  background  : $bg-color;
  color       : $color;
  font-family : $font-family;
  line-height : $baseline;
  @media print {
    background  : transparent none;
    font-family : $serif;
    font-size   : 12pt;
  }
}

// TYPOGRAPHY
// Fliesstext
p {
  line-height : $baseline;
  margin      : 0 0 $baseline;
}

// Ueberschriften
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height : $baseline;
}

h1 {
  margin : 0 0 $baseline 0;
}

h2,
h3,
h4,
h5,
h6 {
  margin : 0 0 0.5 * $baseline 0;
}

h1,
h2,
h3 {
  font-family : $font-family-headline;
  font-weight : $font-weight-headline;
}

h1,
h2 {
  span {
    font-size  : $font-size-header-special;
    font-style : $font-style-header-special;
    // font-weight: 300;
    display    : block;
  }
}

h1 {
  color       : $color-alternative;
  font-size   : $font-size-h1;
  text-shadow : 0 -1px 1px #ffffff, 0 1px 1px #777777;
}

h2 {
  color     : $color-alternative;
  font-size : $font-size-h2;
}

h3 {
  color         : $color-alternative;
  font-size     : $font-size-h3;
  font-style    : italic;
  border-bottom : 1px dotted #cccccc;
}

h4 {
  color         : #000000;
  font-size     : $font-size-h4;
  border-bottom : 1px dotted #777777;
}

h5 {
  background-color : rgba(255, 255, 255, 0.6);
  color            : #cccccc;
  font-size        : $font-size-h5;
  text-transform   : uppercase;
}

h6 {
  color     : #000000;
  font-size : $font-size-h6;
}

.special_font {
  font-family : "Oleo Script Swash Caps", sans-serif;
  line-height : 1.2;
  font-weight : 700;
  color       : #000000;
}

h1.special_font {
  font-size : 2.8em;
}

h2.special_font {
  font-size : 2em;
}

// Listen
ul {
  margin : $baseline 0;
}

// Adresse
address {
  font-style : normal;
  margin     : 0 0 $baseline / 2;
  @include large {
    margin : 0 0 $baseline;
  }
}

// Links
%link {
  &:link {
    color : $color-link-link;
  }

  &:visited {
    color : $color-link-visited;
  }

  @if ($color-link-focus or $color-link-hover) {
    @if ($color-link-focus == $color-link-hover) {
      &:focus,
      &:hover {
        color : $color-link-hover;
      }
    } @else {
      &:focus {
        color : $color-link-focus;
      }
      &:hover {
        color : $color-link-hover;
      }
    }
  }

  &:active {
    color : $color-link-active;
  }
}

a {
  @extend %link;
  font-weight     : 400;
  text-decoration : none;

  &:hover {
    text-decoration : underline;
  }
}

nav {
  a,
  a:hover {
    font-weight     : 300;
    text-decoration : none;
  }
}

// Bilder
img {
  vertical-align : middle;
}

img,
picture {
  max-width : 100%;
  height    : auto;
  @media print {
    break-inside      : avoid;
    page-break-inside : avoid;
  }
}

figure {
  // text-align: center;
  margin : 0 0 $baseline;
}

figcaption {
  font-size : $font-size-small;
  @include medium {
    font-size : $font-size-xsmall;
  }
}

// horizontale Linie
@if $use-hr {
  hr {
    border-bottom      : $border;
    border-left-style  : none;
    border-right-style : none;
    border-top-style   : none;
    margin             : $baseline 0;
  }
}

// Zitate
@if $use-blockquote {
  blockquote {
    border-left  : 2px solid $color-border;
    color        : lighten($color, 15%);
    margin       : $baseline 0;
    padding-left : $baseline / 2;
  }
}

@if $use-cite {
  cite {
    color      : lighten($color, 25%);
    font-style : italic;

    &:before {
      content : "\2014 \00A0";
    }
  }
}

// Button
button {
  font-family : $font-family;
  font-weight : $font-weight;
}

.nowrap {
  white-space : nowrap;
}
