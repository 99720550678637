/*
--------------------------------------------------------------------------------
GENERAL/CONTAO_RESET
Uebernahme einiger von Contao verwendeter Einstellungen
ggf. mit eigenen Anpassungen
 --------------------------------------------------------------------------------
 */
//default Allgemeine Klassen
$use-class-float    : true !default;
$use-class-clear    : true !default;
$use-class-invisible: true !default;

$distance: 1rem !default;
$baseline: 1.625rem !default;

//Floating
@if $use-class-float {
    .float_left,
    .float_right {
      margin-bottom: $baseline;
    }
    .float_left {
      @include medium {
        float: left;
        margin-right: 2 * $baseline;
      }
    }
    .float_right {
      @include medium {
        float: right;
        margin-left: 2 * $baseline;
    }
  }
}
//Clearing
@if $use-class-clear {
  .clear {
    @include medium {
      height:0.1px;
      font-size:0.1px;
      line-height:0.1px;
      clear:both;
    }
  }
  .block {
	   // overflow:hidden; // stoert haeufig, wenn so allgemein eingesetzt
   }
}
// nur fuer Screenreader lesbar
// based on HTML5 Boilerplate:
// github.com/h5bp/html5-boilerplate/issues/194#issuecomment-564745
@if $use-class-invisible {
  .invisible {
    @include hide;
  }
}
div {
  &.back,
  &.more {
    margin-top: $distance;
  }
}
