// Quelle: Geoff Graham https://css-tricks.com/snippets/css/fluid-typography/

// @mixin fluid-typo($min-vw, $max-vw, $min-font-size, $max-font-size) {
//   $u1: unit($min-vw);
//   $u2: unit($max-vw);
//   $u3: unit($min-font-size);
//   $u4: unit($max-font-size);
//
//   @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
//     & {
//       font-size: $min-font-size;
//       @media screen and (min-width: $min-vw) {
//         font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
//       }
//       @media screen and (min-width: $max-vw) {
//         font-size: $max-font-size;
//       }
//     }
//   }
// }

// Quelle: http://codepen.io/MadeByMike/pen/bEEGvv
@mixin fluid-typo($properties, $min-vw, $max-vw, $min-value, $max-value) {
	& {
		@each $property in $properties {
			#{$property}: $min-value;
		}

		@media screen and (min-width: $min-vw) {
			@each $property in $properties {
				#{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
		}

		@media screen and (min-width: $max-vw) {
			@each $property in $properties {
				#{$property}: $max-value;
			}
		}
	}
}
